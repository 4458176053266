import Accordion from "./Classes/Accordion";
import Swiper from "swiper";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import ResponsiveTablesLight from "./Classes/ResponsiveTablesLight";
import CountUpNumbers from "./Classes/CountUpNumbers";
import Video from "./Classes/Video";
import HashHandler from "./Classes/HashHandler";

window.addEventListener("page_event_load", () => {
    new Accordion({
        accordions: '.lc-accordion__inner-head',
        pxCorrectionMobile: 110,
        pxCorrectionDesktop: 105,
        mobileBreakpoint: 1025
    });

    new HashHandler();

    new Swiper('.lc-hero-slider.swiper', {
        modules: [Navigation, Pagination, Autoplay],
        loop: true,
        speed: 900,
        autoplay: {
            delay: 5000,
            disableOnInteraction: true,
        },
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    });

    new Swiper('.lc-slider .swiper', {
        modules: [Navigation, Pagination],
        loop: true,
        slidesPerView: 1,
        spaceBetween: 64,
        breakpoints: {
            992: {
                slidesPerView: 2,
                spaceBetween: 32,
            },
            1250: {
                slidesPerView: 3,
                spaceBetween: 32,
            },
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    });


    new ResponsiveTablesLight({
        tableComponentSelector: '.lc-component--table',
        tableContainerSelector: '.ns-table-container',
        tableSelector: '.lc-component--table table'
    });

    new CountUpNumbers({
        numbersSelector: '.lc-keyfigure__number',
        classIn: 'is-in-viewport',
        classOut: 'is-not-in-viewport',
        langArray: ['de', 'en'],
        decimalArray: ['.', '.'],
        thousandSepArray: ['’', '’'],
    });

    new Video();
});

window.addEventListener('note_event_load', evt => {
    // console.log("note loaded event");
    document.body.classList.add("note-open");

    new ResponsiveTablesLight({
        tableComponentSelector: '.lc-component--table',
        tableContainerSelector: '.ns-table-container',
        tableSelector: '.lc-component--table table'
    });


});


window.addEventListener('note_unloaded', evt => {
    document.body.classList.remove("note-open");
});

window.addEventListener('DOMContentLoaded', () => {
})

window.addEventListener('hashchange', (e) => {
    new HashHandler();
    console.log("hash change")

})