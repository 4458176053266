class Video {
  constructor() {
    const thumbnails = Array.prototype.slice.call(document.querySelectorAll('.lc-video__thumbnail-container'));
    thumbnails.forEach(thumbnail => {
      thumbnail.addEventListener('click', () => {
        if (thumbnail) {
          const video = thumbnail.parentElement.parentElement;
          const videoUrl = video.querySelector('.lc-video__video-container');
          const iframe = videoUrl.querySelector('iframe');

          if(iframe) {
            iframe.src = iframe.src + '&autoplay=1';
          } else {
            video.querySelector('video').play();
          }

          thumbnail.classList.add('fade-out');
          setTimeout(() => {
            thumbnail.style.display = 'none';
          }, 400);
        }
      });
    });
  }
}


export default Video;