import Helper from "./Helper";
import { CountUp } from "countup.js";

class CountUpNumbers {
  constructor(config = {}) {
    const {
      numbersSelector,
      classIn,
      classOut,
      langArray,
      decimalArray,
      thousandSepArray,
    } = config;

    this.numbers = document.querySelectorAll(numbersSelector);
    this.numbersArray = Array.prototype.slice.call(this.numbers);
    this.classIn = classIn;
    this.classOut = classOut;

    this.decimal = '';
    this.separator = '';

    // handle multilang separators and decimals
    langArray.forEach((lang, index) => {
      if(document.documentElement.lang == lang){
        this.decimal = decimalArray[index];
        this.separator = thousandSepArray[index];
      }
    });

    //put all the numbers in array, so they are kept even when function is called several times (and numbers will not really be detected)
    this.numberObj = [];
    this.numberObj = this.addNumbersToArray(this.numberObj, this.numbersArray);


    this.options = {
      startVal: 0, // number to start at (0)
      decimalPlaces: 0, // number of decimal places (0)
      duration: 4, // animation duration in seconds (2)
      useEasing: true,
      decimal: this.decimal,
      separator: this.separator,
    };

    if (this.numbers && this.numbers.length !== 0) {
      this.addId(this.numbersArray);
      this.registerScroll();
      this.bindEvents();
    }
  }

  bindEvents() {
    this.numbersArray.forEach((item) => {
      if (Helper.isInViewport(item)) {
        Helper.addClass(item, this.classIn);
        Helper.removeClass(item, this.classOut);
      } else {
        Helper.removeClass(item, this.classIn);
        Helper.addClass(item, this.classOut);
      }
    });
  }

  registerScroll() {
    window.addEventListener("scroll", (e) => {
      this.numbersArray.forEach((item, index) => {
        this.addClassOnScroll(item);
        if (Helper.hasClass(item, this.classIn)) {
          if (!Helper.hasClass(item, "counting")) {
            Helper.addClass(item, "counting");
            this.options.decimalPlaces = this.getDecimalPlaces(item);
            const countUp = new CountUp(
              item.id,
              this.numberObj[index],
              this.options
            );
            countUp.start();
          }
        }
      });
    });
  }

  addClassOnScroll(element) {
    if (Helper.isInViewport(element)) {
      Helper.addClass(element, this.classIn);
      Helper.removeClass(element, this.classOut);
    } else {
      Helper.addClass(element, this.classOut);
      Helper.removeClass(element, this.classIn);
    }
  }

  getDecimalPlaces(item) {
    const text = item.innerText;
    const split = text.split(this.decimal);
    const decimals = split[1];
    const decimalPlaces = (decimals) ? decimals.length : 0;
    return decimalPlaces;
  }

  addNumbersToArray(obj, arr) {
    arr.forEach((item) => {
      let numText = item.innerText;
      let number = numText.replace(this.separator, '');
      let numberWithDecimal = number.replace(this.decimal, '.');
      let anumber = Number(numberWithDecimal);
      obj.push(anumber);
    });
    return obj;
  }

  addId(arr) {
    arr.forEach((item, index) => {
      item.id = "numb" + index;
    });
  }
}

export default CountUpNumbers;
