class ResponsiveTablesLight {
  constructor(config = {}) {
    const {
      tableComponentSelector,
      tableContainerSelector,
      tableSelector
    } = config;

    this.tables = document.querySelectorAll(tableSelector);
    this.tablesArray = Array.prototype.slice.call(this.tables);

    if (this.tables) {
      this.tablesArray.forEach(table => {
        let container = table.closest(tableContainerSelector);
        let component = table.closest(tableComponentSelector);

        this.toggleResponsiveTableClasses(component, container, table);
      });
    }
  }


  toggleResponsiveTableClasses(component, container, table) {
    //console.log(this.hasHorizontalScrollbar(container, table));
      component.classList.add('has-shadow', 'responsive-table');

    if(this.hasHorizontalScrollbar(container, table) === true) {
    } else {
      component.classList.remove('has-shadow', 'responsive-table');
    }

    window.addEventListener('resize', () => {
      if(this.hasHorizontalScrollbar(container, table) === true) {
        component.classList.add('has-shadow', 'responsive-table');
      } else {
        component.classList.remove('has-shadow', 'responsive-table');
      }
    });
  }

  hasHorizontalScrollbar(container, table) {
    const containerWidth = container.clientWidth;
    const tableWidth = table.clientWidth;

    // console.log('container-width:',  containerWidth)
    // console.log('table-width:',  tableWidth)

    return tableWidth > containerWidth;
  }
}

export default ResponsiveTablesLight;
