import Helper from './Helper';

class Accordion {
  constructor(config = {}) {
    const {
      accordions,
      pxCorrectionMobile,
      pxCorrectionDesktop,
      mobileBreakpoint
    } = config;
    this.trigger = accordions;
    this.pxCorrectionMobile = pxCorrectionMobile;
    this.pxCorrectionDesktop = pxCorrectionDesktop;
    this.mobileBreakpoint = mobileBreakpoint;

    this.accordions = Array.prototype.slice.call(document.querySelectorAll(accordions));
    this.currentEl = null;

    if (this.accordions && this.accordions.length !== 0) {
      this.bindEvents();
    }
  }

  bindEvents() {
    this.accordions.forEach(el => {
      el.addEventListener('click', (e) => {
        if (el.classList.contains('active')) {
          Helper.slideClose(el.nextElementSibling);
          el.classList.remove('active');
          el.nextElementSibling.classList.remove('active');
        } else {
          el.classList.add('active');
          Helper.slideOpen(el.nextElementSibling, true);
          el.nextElementSibling.classList.add('active');
        }
      });
    });
  }

  setPxCorrection() {
    if (window.innerWidth >= this.mobileBreakpoint) {
      return this.pxCorrectionDesktop;
    } else {
      return this.pxCorrectionMobile;
    }
  }
}

export default Accordion;
