import Helper from './Helper.js';

class HashHandler {
  constructor() {
    this.scrollToHash();
  }

  scrollToHash() {
    const theId = window.location.hash;
    if (!theId || theId == '#0') {
      return;
    }
    if (theId !== 0) {
      const anchor = document.querySelector(theId);
      if (!anchor) {
        return;
      }

      const accordion = anchor.closest('.lc-accordion');
      if (!!accordion) {
        const accordionHeader = accordion.querySelector('.lc-accordion__head');
        if (!accordionHeader.classList.contains('active')) {
          accordionHeader.click();
        }
      }
      setTimeout(() => {
        this.scroller(anchor, document.querySelector('.iz-header')?.clientHeight || 0);
      }, 200);
    }
  }

  scroller(el, correction) {
    const bounding = el.getBoundingClientRect();
    window.scrollTo({
      top: (bounding.top + window.scrollY - el.offsetHeight - correction),
      left: 0,
      behavior: 'smooth'
    });
  }

}

export default HashHandler;
